<template>
  <v-navigation-drawer
    :value="isSidebarDataSeatsActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-seats-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.info') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-seats-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form v-if="seat">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-switch
                v-model="seat.hidden"
                hide-details
                class="mt-0 ms-2"
                :label="$t('lbl.hidden')"
              ></v-switch>
              <!--<v-text-field
                v-model="header.name"
                :label="$t('lbl.name')"
                hide-details
                dense
                outlined
              ></v-text-field>-->
            </v-col>
          </v-row>

          <div class="d-flex flex-wrap mt-10">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  color="primary"
                  class="me-3"
                  block
                  @click="$emit('update:is-sidebar-data-seats-active', false)"
                >
                  {{ $t('btn.continue') }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  color="secondary"
                  outlined
                  block
                  @click="$emit('update:is-sidebar-data-seats-active', false)"
                >
                  {{ $t('btn.cancele') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose, mdiAccountCashOutline } from '@mdi/js'

export default {
  model: {
    prop: 'isSidebarDataSeatsActive',
    event: 'update:is-sidebar-data-seats-active',
  },
  props: {
    isSidebarDataSeatsActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    posSeat: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    position: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiAccountCashOutline,
      },
    }
  },
  computed: {
    ...mapState({
      seasting: state => state.app.seasting,
    }),
    seat() {
      let item = null
      if (this.position === 'left') {
        item = this.seasting.seatsLeft[this.posSeat]
      } else {
        item = this.seasting.seatsRight[this.posSeat]
      }

      return item
    },
  },
  methods: {
    ...mapMutations(['setCheckAll', 'updateItemCheck']),
  },
}
</script>
