<!-- eslint-disable vue/valid-v-model -->
<template>
  <fragment>
    <span v-if="posHead === 0 && isLeft">
      {{ row }}
    </span>
    <v-chip
      class="mr-1 mt-1 sizeChip"
      :color="seat.hidden ? 'color: white' : 'orange'"
      label
      outlined
      @click="setSeatLocal()"
    >
      <!--style="color: white"-->
      <span
        v-if="isLeft"
        :style="seat.hidden ? 'color: white' : ''"
      >
        {{ `${seasting.headersLeft[posHead].name}${seat.row}` }}
      </span>
      <span
        v-else
        :style="seat.hidden ? 'color: white' : ''"
      >
        {{ `${seasting.headersRight[posHead].name}${seat.row}` }}
      </span>
    </v-chip>
  </fragment>
</template>
<script>
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    seat: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    row: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    posHead: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    isLeft: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapState({
      seasting: state => state.app.seasting,
    }),
  },
  methods: {
    ...mapMutations(['initSeasting']),
    setSeatLocal() {
      this.$emit('setSeat', { pos: this.pos, position: this.isLeft ? 'left' : 'right' })
    },
  },
}
</script>

<style scoped>
.sizeChip {
  width: 45px;
  text-align: center !important;
}
/* .short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
</style>
