<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="vl-parent">
    <v-row>
      <v-col
        cols="12"
        md="8"
        offset-md="2"
      >
        <v-row class="text-center">
          <!--BOTONERA IZQ-->
          <v-col
            cols="12"
            md="4"
            class="text-right"
          >
            <v-btn
              color="primary"
              class="mr-2"
              fab
              x-small
              dark
              outlined
              :disabled="seasting.count.columnsLeft === 1"
              @click="deleteLeft()"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              fab
              x-small
              dark
              outlined
              :disabled="seasting.count.columnsLeft === 4"
              @click="addLeft()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>

          <!--BOTONERA DER-->
          <v-col
            cols="12"
            md="3"
            offset-md="1"
            class="text-left"
          >
            <v-btn
              color="primary"
              class="mr-2"
              fab
              x-small
              dark
              outlined
              :disabled="seasting.count.columnsRight === 1"
              @click="deleteRight()"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              fab
              x-small
              dark
              outlined
              :disabled="seasting.count.columnsRight === 4"
              @click="addRight()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!--HEADERS-->
        <v-row
          no-gutters
          class="text-center"
        >
          <!--IZQUIERDA-->
          <v-col
            cols="12"
            md="4"
            class="text-right"
          >
            <v-chip
              v-for="(header, i) in seasting.headersLeft"
              :key="i"
              label
              class="mr-1 mt-1 sizeChip"
              color="primary"
              outlined
              @click="setHeader(i, 'left')"
            >
              {{ header.name }}
            </v-chip>
          </v-col>
          <!--PAZILLO-->
          <v-col
            cols="12"
            md="1"
          >
            <v-chip
              class="mr-1 mt-1 sizeChip"
              color="white"
              label
              outlined
            >
            </v-chip>
          </v-col>
          <!--DERECHA-->
          <v-col
            cols="12"
            md="4"
            class="text-left"
          >
            <v-chip
              v-for="(header, i) in seasting.headersRight"
              :key="i"
              label
              class="mr-1 mt-1 sizeChip"
              color="primary"
              outlined
              @click="setHeader(i, 'right')"
            >
              {{ header.name }}
            </v-chip>
          </v-col>
          <!--BOTONERA ROWS-->
          <v-col
            cols="12"
            md="3"
            class="text-left"
          >
            <v-btn
              color="primary"
              class="mr-2"
              fab
              x-small
              dark
              outlined
              @click="addRows()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              fab
              x-small
              dark
              outlined
              :disabled="seasting.count.rows === 10"
              @click="deleteRows()"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--ROWS-->
        <v-row
          v-for="r in seasting.count.rows"
          :key="r"
          no-gutters
          class="text-center"
        >
          <v-col
            cols="12"
            md="12"
            class="text-center"
          >
            <v-row
              no-gutters
              class="text-center"
            >
              <!--IZQUIERDA-->
              <v-col
                cols="12"
                md="4"
                class="text-right"
              >
                <fragment
                  v-for="(header, i) in seasting.headersLeft"
                  :key="i"
                >
                  <fragment
                    v-for="(seat, indS) in seasting.seatsLeft"
                    :key="indS"
                  >
                    <fragment v-if="i === seat.posHead && seat.row === r">
                      <Seats
                        :seat="seat"
                        :row="r"
                        :pos-head="i"
                        :pos="indS"
                        :is-left="true"
                        @setSeat="setSeat"
                      />
                    </fragment>
                  </fragment>
                </fragment>
              </v-col>
              <!--PAZILLO-->
              <v-col
                cols="12"
                md="1"
              >
                <v-chip
                  class="mr-1 mt-1"
                  color="white"
                  label
                  outlined
                >
                </v-chip>
              </v-col>
              <!--DERECHA-->
              <v-col
                cols="12"
                md="4"
                class="text-left"
              >
                <fragment
                  v-for="(header, i) in seasting.headersRight"
                  :key="i"
                >
                  <fragment
                    v-for="(seat, indS) in seasting.seatsRight"
                    :key="indS"
                  >
                    <fragment v-if="i === seat.posHead && seat.row === r">
                      <Seats
                        :seat="seat"
                        :row="r"
                        :pos-head="i"
                        :pos="indS"
                        :is-left="false"
                        @setSeat="setSeat"
                      />
                    </fragment>
                  </fragment>
                </fragment>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <SidebarDataHead
      v-model="isSidebarDataHeadActive"
      :pos-head="posHead"
      :position="position"
    />
    <SidebarDataSeats
      v-model="isSidebarDataSeatsActive"
      :pos-seat="posSeat"
      :position="position"
    />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import Seats from './utils/Seats.vue'
import SidebarDataHead from './utils/SidebarDataHead.vue'
import SidebarDataSeats from './utils/SidebarDataSeats.vue'

export default {
  components: {
    Seats,
    SidebarDataHead,
    SidebarDataSeats,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    init: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      isSidebarDataHeadActive: false,
      isSidebarDataSeatsActive: false,
      posHead: null,
      position: null,
      posSeat: null,
    }
  },
  computed: {
    ...mapState({
      seasting: state => state.app.seasting,
    }),
  },
  created() {
    this.initHeaders()
  },
  methods: {
    ...mapMutations([
      'initSeasting',
      'addLeftSeasting',
      'deleteLeftSeasting',
      'addRightSeasting',
      'deleteRightSeasting',
      'addRowSeasting',
      'deleteRowSeasting',
    ]),
    initHeaders() {
      if (this.init) {
        this.initSeasting()
      }
    },

    addLeft() {
      this.addLeftSeasting(this.seasting.headersLeft.length + 1)
    },
    deleteLeft() {
      this.deleteLeftSeasting(this.seasting.headersLeft.length - 1)
    },

    addRight() {
      this.addRightSeasting(this.seasting.headersRight.length + 1)
    },
    deleteRight() {
      this.deleteRightSeasting(this.seasting.headersRight.length - 1)
    },

    addRows() {
      this.addRowSeasting()
    },
    deleteRows() {
      this.deleteRowSeasting()
    },

    setHeader(posHeader, position) {
      this.posHead = posHeader
      this.position = position

      this.isSidebarDataHeadActive = true
    },
    setSeat(obj) {
      this.posSeat = obj.pos
      this.position = obj.position

      this.isSidebarDataSeatsActive = true
    },
  },
}
</script>

<style scoped>
.sizeChip {
  width: 45px;
  text-align: center !important;
}
</style>
